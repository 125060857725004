import React from 'react';
import { Helmet } from 'react-helmet-async';
import { CmsPageQuery } from '../../elements/Cms/CmsQuery';
import Container from '../../layout/Container/Container';
import { Breadcrumbs } from '../../elements/Breadcrumbs/Breadcrumbs';
import { GoogleTagManager4Pageview } from '../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { isAppMobile } from '../../helpers/AppMobile/AppMobile';
import loadable from '../../../../../components/loadable';
import SEOStructuredData from '../Home/components/SEOStructuredData';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../elements/Cms/Row'));

const Page = withStoreConfiguration(({ storeConfiguration, id, path }) => {
  const stores = storeConfiguration?.asam_store?.language_switch?.external_systems || null;
  const current = storeConfiguration?.web?.secure?.base_url || null;
  const storeBase = current ? new URL(current) : null;
  const base = storeBase.origin || null;
  const shouldShowLink = contentfulPage =>
    contentfulPage?.contentData?.index && !contentfulPage?.contentData?.index?.includes('NOINDEX');

  // identify magazine pageType for tracking
  const magazineSlug = storeConfiguration?.asam_tracking?.googletagmanager?.magazine_url_identifier;
  let pageType = 'landingpage';

  if (magazineSlug !== undefined && path?.includes(magazineSlug)) {
    pageType = 'magazin';
  }

  return (
    <React.Fragment>
      <CmsPageQuery variables={{ id, path }}>
        {({ data: { contentfulPage }, loading }) => {
          if (loading) {
            return (
              <Container>
                <div className="PageLoaderContainer">
                  <div className={['PageLoader', 'PageLoaderDesktop', 'HideMobile'].join(' ')} />
                  <div className={['PageLoader', 'PageLoaderMobile', 'ShowMobile'].join(' ')} />
                </div>
              </Container>
            );
          }
          if (contentfulPage?.contentData) {
            return (
              <React.Fragment>
                <Helmet>
                  {contentfulPage?.contentData?.meta_title && <title>{contentfulPage.contentData.meta_title}</title>}
                  {contentfulPage?.contentData?.meta_title && (
                    <meta property="og:title" content={contentfulPage.contentData.meta_title} />
                  )}

                  {contentfulPage?.contentData?.meta_description && (
                    <meta name="description" content={contentfulPage.contentData.meta_description} />
                  )}
                  {contentfulPage?.contentData?.meta_description && (
                    <meta property="og:description" content={contentfulPage.contentData.meta_description} />
                  )}

                  {current && <meta property="og:url" content={`${current}${path.slice(1)}`} />}
                  {!contentfulPage.previewMode && contentfulPage?.contentData?.index ? (
                    <meta name="robots" content={contentfulPage.contentData.index} />
                  ) : (
                    <meta name="robots" content="NOINDEX, NOFOLLOW, NOARCHIVE" />
                  )}
                  {contentfulPage?.contentData?.canonicalLink && shouldShowLink(contentfulPage) && (
                    <link rel="canonical" href={`${contentfulPage.contentData.canonicalLink}`} />
                  )}
                  {!contentfulPage?.contentData?.canonicalLink && current && shouldShowLink(contentfulPage) && (
                    <link rel="canonical" href={`${current}${path.slice(1)}`} />
                  )}
                  {stores &&
                    base &&
                    contentfulPage?.storeUrls?.map(store => {
                      if (store.storeCode === 'de' && stores.includes('de-DE') && shouldShowLink(contentfulPage)) {
                        return (
                          <link
                            key={`href_${store.storeCode}`}
                            rel="alternate"
                            hreflang="de-DE"
                            href={`${base}/${store.url}`}
                          />
                        );
                      }
                      if (store.storeCode === 'de-CH' && stores.includes('de-CH') && shouldShowLink(contentfulPage)) {
                        return (
                          <link
                            key={`href_${store.storeCode}`}
                            rel="alternate"
                            hreflang="de-CH"
                            href={`${base}/de_ch/${store.url}`}
                          />
                        );
                      }
                      if (store.storeCode === 'fr-CH' && stores.includes('fr-CH') && shouldShowLink(contentfulPage)) {
                        return (
                          <link
                            key={`href_${store.storeCode}`}
                            rel="alternate"
                            hreflang="fr-CH"
                            href={`${base}/fr_ch/${store.url}`}
                          />
                        );
                      }
                      if (store.storeCode === 'fr' && stores.includes('fr-FR') && shouldShowLink(contentfulPage)) {
                        return (
                          <link
                            key={`href_${store.storeCode}`}
                            rel="alternate"
                            hreflang="fr-FR"
                            href={`${base}/fr/${store.url}`}
                          />
                        );
                      }
                      return null;
                    })}
                </Helmet>

                {!isAppMobile && contentfulPage?.contentData?.breadcrumb ? (
                  <Container>
                    <Breadcrumbs
                      breadcrumbs={[
                        {
                          name: `${contentfulPage.contentData.name}`,
                          urlPath: `/${contentfulPage.contentData?.url_slug}`
                        }
                      ]}
                    />
                  </Container>
                ) : null}
                {contentfulPage?.contentData?.modules?.map((item, index) => (
                  <Row
                    key={`Row -'${index}'`} // eslint-disable-line
                    row={index}
                    item={item.fields}
                    contentType={item.sys.contentType.sys.id}
                  />
                ))}
                <SEOStructuredData JSONdata={contentfulPage?.contentData?.ld_json} />
                <GoogleTagManager4Pageview
                  dataLayerData={{ page_type: pageType, page_title: contentfulPage?.contentData?.meta_title }}
                />
              </React.Fragment>
            );
          }
        }}
      </CmsPageQuery>
    </React.Fragment>
  );
});

export default Page;
